.basicHome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  height: 100vh;

  & > img {
    height: 8rem;
  }

  & > .title {
    display: none;
    margin-left: 1.75rem;
    margin-right: auto;
    margin-top: 2rem;
  }

  & > .mainNavigations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    max-width: 1200px;
    width: 100%;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5rem 5rem;
      background: #fafafa;
      border-radius: 10px;
      cursor: pointer;
      font-weight: 520;
      transition: all linear 0.3s;

      &:hover {
        color: white;
        background: var(--main-color);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .basicHome {
    padding: 1rem 10px;
    justify-content: space-between;

    & > img {
      height: 3rem;
    }

    & > .title {
      display: block;
      font-size: 1.25rem;
      font-weight: 510;
    }

    & > .mainNavigations {
      grid-template-columns: repeat(1, 1fr);
      // height: calc(100% - 8rem);
      margin-bottom: auto;
      overflow-y: scroll;

      & > div {
        height: max-content;
        padding: 1.2rem 1.6rem;
        justify-content: left;
        border-radius: 35px;
      }
    }
  }
}
