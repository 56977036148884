.dropdown {
  width: 261px;
  // height: 247px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background: white;
  z-index: 1;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dropdown1 {
  width: 261px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background: white;
  z-index: 1;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: 20px;
}

.dropItem {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  &:hover {
    .dropText {
      font-weight: 600;
    }
    .dropText1 {
      font-weight: 600;
    }
  }
}
.dropText {
  color: var(--main-color);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  // line-height: 24px; /* 171.429% */
  padding-left: 20px;
}
.dropText1 {
  color: var(--main-color);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  // line-height: 24px; /* 171.429% */
  // padding-left: 20px;
}
