.popupWrapper {
  padding: 28px;
}
.popupTitle {
  color: #5f6163;
  // font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}
.allProfilesStatus {
  & > div {
    display: flex;
    align-items: center;
    padding: 1rem 0.7rem;
    justify-content: space-between;
    font-size: 0.9rem;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    margin-bottom: 0.6rem;
    cursor: pointer;
    font-weight: 460;

    .parentWithFunc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      & > .createBtn {
        border: 1px solid #e1e1e1;
        border-radius: 7px;
        height: 100%;
        padding: 0.3rem 1.5rem;
        cursor: pointer;
        font-size: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all linear 0.3s;

        &:hover {
          background: var(--main-color);
          color: white;
          border: 1px solid var(--main-color);
        }
      }
    }

    & > .createBtn {
      border: 1px solid #e1e1e1;
      border-radius: 7px;
      height: 100%;
      padding: 0.3rem 1.5rem;
      cursor: pointer;
      font-size: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all linear 0.3s;

      &:hover {
        background: var(--main-color);
        color: white;
        border: 1px solid var(--main-color);
      }
    }

    & > svg {
      font-size: 1rem;
      color: var(--main-color);
    }
  }

  & > .notSelected {
    opacity: 0.5;
    pointer-events: none;
  }
}
.dropdown {
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 0px 20px;
}
.flexDiv {
  display: flex;
  align-items: center;
  gap: 1rem;
  border: none !important;
  padding: 5px 0.7rem !important;

  & > .singleDiv {
    width: 100%;

    & > .title {
      margin-bottom: 5px;
    }
  }
}
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.inputContainers {
  display: flex;
  gap: 1rem;
  border: none !important;
  justify-content: normal !important;

  & > .imageUpload {
    display: flex;
    flex-direction: column;
    border: 1px solid #e1e1e1;
    border-radius: 7px;
    position: relative;

    & > img {
      width: 6vw;
      height: 6vw;
      border-radius: 7px;
    }

    & > .uploadingimgLogo {
      height: 6vw;
      width: 8vw;
      aspect-ratio: 1.3/1;
      border-radius: 7px;
    }

    & > .imageUploadLabel {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      padding: 1rem;

      & > img {
        width: calc(6vw - 1rem);
        height: calc(6vw - 1rem);
        border-radius: 7px;
      }

      & > input {
        display: none;
      }
    }

    & > .imageUploadLabelLogo {
      aspect-ratio: 1.3/1;
    }

    & > .title {
      position: absolute;
      bottom: -2rem;
      // top: 50%;
      left: 50%;
      white-space: nowrap;
      text-align: center;
      transform: translate(-50%, 0%);
    }
  }

  // & > div {
  //   flex-direction: column-reverse;

  //   & > label {
  //     border-radius: 7px;

  //     & > img {
  //       border-radius: 7px;
  //     }
  //   }
  // }

  // img {
  //   border-radius: 7px !important;
  // }

  // & > :nth-child(2) {
  //   & > :nth-child(2) {
  //     margin-top: 0 !important;
  //     height: 8vw;
  //     aspect-ratio: 1.4/1;
  //     border-radius: 7px;
  //   }
  // }
}

.submitBtn {
  padding: 0.7rem 1.5rem !important;
  background: var(--main-color);
  color: white;
  font-weight: 520;
  font-size: 1.1rem;
  cursor: pointer;
  text-align: center !important;
  margin: 0rem;
  margin-top: 2rem;
  justify-content: center !important;
  transition: all linear 0.3s;

  &:hover {
    scale: 1.05;
  }
}
.inputStyle {
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 0px 20px;
  width: 100%;
  margin-top: 20px;
}
.textareaStyle {
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  width: 100%;
  padding: 10px 20px;
}
.bottomContainer {
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #e9ebf3;
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(217, 217, 217, 0.3);
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
}
.calendarWrapper {
  border-radius: 5px;
  background: #fff;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 20px;
  width: 50%;

  color: #5f6163;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}
.calendarIcon {
  width: 24px;
  height: 24px;
}
.uploadContainer {
  width: 192px;
  height: 192px;
  border-radius: 5px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #e9ebf3;
}
.upldImg {
  width: 52px;
  height: 52px;
}
.upldTxt {
  color: #5f6163;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 20px;
}

.dropdown1 {
  border-radius: 5px;
  border: 1px solid #e9ebf3;
  background: #fff;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  height: 200px;
  padding: 0px 20px;
}
.individualQuote {
  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
  div:nth-child(1) {
    color: #5f6163;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  div:nth-child(2) {
    color: #5f6163;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
.addBtn {
  margin-top: 20px;
  border-radius: 5px;
  background: var(--opacity-main);
  width: 236px;
  height: 45.564px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  &:hover {
    background-color: white;
    border: 1px solid #e7e7e7;
    color: black;
  }
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: 4;
  // background: rgba(0, 0, 0, 0.8);
  z-index: 7;
}

.quoteDetailsWrapper {
  z-index: 9;
  border: 1px solid #e5e5e5;
  background: #fff;
  width: 65%;
  height: 55vh;
  position: fixed;
  top: 0;
  margin-left: 5%;
  margin-top: 20%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  overflow-y: scroll;
}
.iqTitle {
  color: #5f6163;
  text-align: justify;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 10px;
}
.iqcardWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.selectedBtn {
  border-radius: 5px;
  border: 1px solid var(--opacity-main);
  width: 192px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: var(--opacity-main);
  color: var(--opacity-main);
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}
.notSelectedBtn {
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  width: 192px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  // color: var(--opacity-main);
  background: white;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  &:hover {
    border: 1px solid var(--opacity-main);
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    color: var(--opacity-main);
    cursor: pointer;
  }
}

.bulkUpload {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0rem;
  overflow-y: scroll;

  & > .loadingImage {
    position: absolute;
    inset: 0;
    pointer-events: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      height: 5rem;
      animation: heartbeat 1.3s infinite;
    }

    @keyframes heartbeat {
      0% {
        transform: scale(0.75);
      }
      20% {
        transform: scale(1);
      }
      40% {
        transform: scale(0.75);
      }
      60% {
        transform: scale(1);
      }
      80% {
        transform: scale(0.75);
      }
      100% {
        transform: scale(0.75);
      }
    }
  }

  & > .title {
    font-size: 1rem;
    font-weight: 550;
    text-align: left;
  }

  & > .fieldsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & > .singleInput {
      // max-width: 300px;

      & > div {
        & > div {
          border-radius: 10px;
        }
      }
    }

    & > .selectInsuredType,
    .selectOtherOptions {
      display: flex;
      gap: 1.2rem;
      margin: 1.2rem 0;

      & > div {
        position: relative;
        width: 200px;

        & > label {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          position: relative;
          white-space: nowrap;
          left: 0;
          top: 0;
          transform: translate(0, 0);

          & > :last-child {
            margin-left: 1rem;
          }
        }
      }
    }

    & > .selectOtherOptions {
      display: flex;
      gap: 1rem;
      align-items: center;
      max-width: 600px;

      & > div {
        & > label {
          & > :last-child {
            font-size: 0.9rem;
            margin-left: 2.5rem;
          }
        }
      }
    }

    & > .sumInsuredInputs {
      max-width: 400px;

      & > .gradedInputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > .eachGrade {
          display: flex;
          // justify-content: space-between;
          gap: 1rem;
          align-items: center;

          & > .addInput {
            visibility: hidden;
            pointer-events: none;
          }
        }

        & > :last-child {
          & > .addInput {
            visibility: visible;
            pointer-events: unset;
          }

          // & > div {
          //   pointer-events: none;
          // }
        }
      }
    }

    & > .listContainer {
      list-style: disc;
      margin-top: 1rem;
      margin-left: 1.5rem;
      width: calc(100% - 3rem);

      & > li {
        padding: 10px 0;
        margin: 0;
      }
    }

    & > .manyInputs {
      & > .title {
        font-size: 0.9rem;
        margin-bottom: 10px;
        font-weight: 500;
      }

      & > .gridContainer {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
      }
    }

    & > .switchTable {
      tr {
        display: grid;
        gap: 1rem;
        grid-template-columns: 250px repeat(3, 1fr);
        //   align-items: center;

        & > td {
          padding: 0.5rem 1.2rem;

          img {
            cursor: pointer;
            transition: all linear 0.3s;

            &:hover {
              scale: 1.25;
            }
          }
        }

        & > :nth-child(2),
        :nth-child(3),
        :nth-child(4) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  & > .bulkDocuments {
    display: flex;
    gap: 1rem;

    img,
    svg {
      height: 4rem;
      width: 4rem;
    }

    & > div {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
      gap: 1rem;
      border-radius: 10px;
      border: 1px solid #e1e1e1;
      width: 12rem;
      height: 12rem;
      aspect-ratio: 1/1;
      transition: all linear 0.3s;

      label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > div {
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
        }
      }

      & > span {
        text-overflow: "ellipse";
        overflow: "hidden";
        white-space: "nowrap";
        text-align: center;
      }

      &:hover {
        border: 1px solid var(--main-color);
      }
    }
  }

  & > .btnsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;

    & > button {
      padding: 0.5rem 1.5rem;
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 10px;
      font-weight: 500;
    }

    // & > :nth-child(1) {
    //   background: white;
    //   border: 1px solid #e5e5e5;
    // }
    & > :nth-child(1),
    .coloredBtn {
      background: var(--main-color);
      color: white;
      border: 1px solid #e5e5e5;
    }
  }
}

.rahaAddContainer {
  position: fixed;
  inset: 0;
  border-radius: 10px;
  z-index: 10;

  & > .overlay {
    position: absolute;
    inset: 0;
    background: #00000080;
  }

  & > .addRahaEmployees {
    padding: 2rem;
    background: white;
    border-radius: 10px;
    position: absolute;
    width: 550px;
    aspect-ratio: 1/1.1;
    z-index: 8;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: scroll;

    & > .loadingImage {
      position: absolute;
      inset: 0;
      z-index: 8;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        height: 5rem;
        animation: heartbeat 1.3s infinite;
      }

      @keyframes heartbeat {
        0% {
          transform: scale(0.75);
        }
        20% {
          transform: scale(1);
        }
        40% {
          transform: scale(0.75);
        }
        60% {
          transform: scale(1);
        }
        80% {
          transform: scale(0.75);
        }
        100% {
          transform: scale(0.75);
        }
      }
    }

    & > .title {
      font-size: 1.2rem;
      font-weight: 550;
      margin: 0.5rem 0;
    }

    & > .employeeDetails {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1.5rem;

      & > .twoFields {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;

        & > div {
          width: 50%;
        }
      }

      & > div {
        & > .subTitle {
          font-size: 0.9rem;
          font-weight: 500;
          margin-bottom: 10px;
        }
      }

      & > .selectMultiple {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        & > div {
          padding: 0.8rem 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #e1e1e1;
          font-size: 0.85rem;
          border-radius: 7px;
          cursor: pointer;
        }

        & > .selectedRole {
          border: 1px solid var(--main-color);
          color: var(--main-color);
        }
      }

      & > .submitBtn {
        font-size: 0.9rem;
        border-radius: 7px;
        width: max-content;
      }
    }
  }
}
