// .tabWrapper{
//     display: flex;
//     // justify-content: space-between;
//     // flex-wrap: nowrap;
//     // align-items: center;
//     gap: 11px;
//     width: calc(100% - 2rem);
//     overflow-x: scroll;
//     // width: 10vw;
//     padding: 40px 17px;

// }
// .tabItem{
//     // display: block;
//     background-color: rgba(95, 97, 99, 0.15);
//     // padding: 10px 30px;
//     // // width: auto;
//     // width: 400px !important;
//     border-radius: 5px;
//     width: 300px;
// }

.tabWrapper {
  display: flex;
  gap: 11px;
  width: 70vw;
  overflow-x: auto; /* Use 'auto' to enable horizontal scroll */
  padding: 30px 17px;
  white-space: nowrap; /* Prevent wrapping of tabItems to a new line */
}

.tabItem {
  padding: 10px 30px;
  background-color: rgba(95, 97, 99, 0.15);
  border-radius: 5px;
  white-space: nowrap; /* Prevent wrapping of tabItem content */
  font-size: 11px;
  font-weight: 600;
  // overflow: hidden; /* Hide overflow content */
  // text-overflow: ellipsis; /* Show ellipsis (...) for overflow text */
}
.selectedTabItem {
  padding: 10px 30px;
  background-color: var(--opacity-main);
  border-radius: 5px;
  color: white;
  font-size: 11px;
  font-weight: 600;
}

.rowStyle {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding-top: 10px;
  padding: 10px 20px;
}

.userDetailTitle {
  color: var(--main-color);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.userDetailValue {
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0px 10px;
  div:nth-child(1) {
    color: var(--main-color);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.userDetailValue1 {
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  padding: 0px 10px;
  div:nth-child(1) {
    color: var(--main-color);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
