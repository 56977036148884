// .tabWrapper{
//     display: flex;
//     // justify-content: space-between;
//     // flex-wrap: nowrap;
//     // align-items: center;
//     gap: 11px;
//     width: calc(100% - 2rem);
//     overflow-x: scroll;
//     // width: 10vw;
//     padding: 40px 17px;

// }
// .tabItem{
//     // display: block;
//     background-color: rgba(95, 97, 99, 0.15);
//     // padding: 10px 30px;
//     // // width: auto;
//     // width: 400px !important;
//     border-radius: 5px;
//     width: 300px;
// }

.tabWrapper {
  display: flex;
  gap: 11px;
  width: 70vw;
  overflow-x: auto; /* Allow horizontal scroll */
  padding: 30px 17px;
  white-space: nowrap; /* Prevent wrapping of tabItems to a new line */
}

.tabItem {
  flex: 0 0 486px;
  height: 210;
  padding: 12px 18px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
}
.selectedTabItem {
  padding: 10px 30px;
  background-color: var(--opacity-main);
  border-radius: 5px;
  color: white;
  font-size: 11px;
  font-weight: 600;
}

.rowStyle {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding-top: 10px;
  padding: 10px 20px;
}

.userDetailTitle {
  color: var(--main-color);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.userDetailValue {
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0px 10px;
  div:nth-child(1) {
    color: var(--main-color);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.userDetailValue1 {
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  padding: 0px 10px;
  div:nth-child(1) {
    color: var(--main-color);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
.row1 {
  display: flex;
  align-items: center;
  padding-bottom: 23px;
  img {
    width: 65px;
    height: 53px;
    border-radius: 10px;
    object-fit: contain;
    border: 1px solid #e7e7e7;
    margin-right: 10px;
  }
  .compDetails {
    :nth-child(1) {
      font-size: 20px;
      font-weight: 700;
    }
    :nth-child(2) {
      font-size: 15px;
      font-weight: 400;
      opacity: 0.5;
    }
  }
}
.row2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: var(--main-color);
  padding-bottom: 13px;
}
.row3 {
  padding-top: 20px;
  font-size: 10px;
  font-weight: 500;
}
.innerCardWrapper {
  height: 100px;
  // background-color: gray;
  overflow-y: scroll;
  padding-top: 16px;
}
.innerCard {
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  margin-bottom: 8px;
  padding: 0px 20px;
  font-size: 12px;
  font-weight: 600;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // transform: scale(1.05);
    // transition: all 0.6s ease;
    cursor: pointer;
  }
}
.row4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  :nth-child(1) {
    font-size: 20px;
    font-weight: 700;
  }
  :nth-child(2) {
    width: 157px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eaeaeb;
    border-radius: 10px;
    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
  }
}
